.tl__channels-container_channel {
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    background: var(--color-highlight);

    cursor: pointer;
}

.tl__channels-container_channel-image {
    width: 100%;
    height: 100%;
    background: var(--color-footer)
}

.tl__channels-container_channel-image img {
    width: 100%;
    height: 100%;
}

.tl__channels-container_channel-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 1rem 1.5rem;
    height: 100%;
}

.tl__channels-container_channel-content p {
    font-family: var(--font-family);
    color: #FFF;
}


.tl__channels-container_channel-content h3 {
    
}

.tl__channels-container_channel-content p:last-child {
    
}