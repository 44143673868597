@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --font-family: 'Manrope', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #e6d0ff 1.84%, #cb93ff 102.67%);
  
  --color-bg: #070707;
  --color-footer : #0c0c0d;
  --color-text: #dddddd;
  --color-subtext: #8f6eaf;
  --color-highlight: #744dbd;
  --color-highlight-light: #e6d0ff;
}
