.active {
    color: var(--color-highlight-light);
}

.tl__navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    padding: 2rem 6rem;
}

.tl__navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.tl__navbar-links_logo {
    margin-right: 2rem;
}

.tl__navbar-links_logo img{
    width: 32px;
    height: 32px;
}

.tl__navbar-links_container {
    display: flex;
    flex-direction: row;
}

.tl__navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.tl__navbar-links_container p,
.tl__navbar-sign p,
.tl__navbar-menu_container p {
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.tl__navbar-sign button,
.tl__navbar-menu_container button {
    padding: 0.5rem 1rem;
    color: #fff;
    background: var(--color-highlight);
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    border-radius: 5px;
    border: 0;
    outline: none;
    cursor: pointer;
}

.tl__navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.tl__navbar-menu svg {
    cursor: pointer;
}

.tl__navbar-menu_container {
    display:flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;

    text-align: end;
    background: var(--color-footer);
    padding: 2rem;
    position: absolute;
    top: 40px;
    right: 0;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 5px;
    box-shadow: 0 0 5 rgba(0,0,0,0.2);
}

.tl__navbar-menu_container p {
    margin: 1rem 0;
}

.tl__navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .tl__navbar-links_container {
        display: none;
    }
    .tl__navbar-menu {
        display: flex;
    }
}

@media screen and (max-width: 700px) {
    .tl__navbar {
        padding: 2rem 4rem;
    }
}


@media screen and (max-width: 550px) {
    .tl__navbar {
        padding: 2rem;
    }

    .tl__navbar-sign {
        display: none;
    }

    .tl__navbar-menu_container {
        top: 20px;
    }

    .tl__navbar-menu_container-links-sign {
        display: block;
    }
}
