* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
   background: var(--color-bg)
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 4% 13%, rgba(43, 43, 43, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 4% 13%, rgba(43, 43, 43, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 4% 13%, rgba(43, 43, 43, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 4% 13%, rgba(43, 43, 43, 1) 0%, rgba(0, 0, 0, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 4% 13%, rgba(43, 43, 43, 1) 0%, rgba(0, 0, 0, 1) 100%);
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.blurry-text {
    filter:blur(3px);
 }

.tl__logo_text {
    font-family: var(--font-family);
    color: #FFF;
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    padding: 4rem 6rem;
}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.5);
              transform: scale(0.5);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

@keyframes scale-up-center {
    0% {
        -webkit-transform: scale(0.5);
                transform: scale(0.5);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

.scale-up-ver-top {
	-webkit-animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-ver-top 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
  }
  @keyframes scale-up-ver-top {
    0% {
      -webkit-transform: scaleY(0.4);
              transform: scaleY(0.4);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
    100% {
      -webkit-transform: scaleY(1);
              transform: scaleY(1);
      -webkit-transform-origin: 100% 0%;
              transform-origin: 100% 0%;
    }
}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }
    .section__margin {
        padding: 4rem;
    }
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }
    .section__margin {
        padding: 4rem 2rem;
    }
}

.hover-underline-animation {
    display: block;
    position: relative;
    color: #FFF;
}
  
.hover-underline-animation:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #FFF;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}
  
.hover-underline-animation:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

/* 
.App {
    background: #282c34;
    overflow: hidden;
} */

/* .content {
    margin-top: 60px;
    padding: 12px;
}

.main-container {
    max-width: 850px;
    margin: 0 auto;
    border: 0;
    align-items: center;
    justify-content: center;
}

.table-container {
    display: flex;
    flex-direction: column;
    color: lightgray;
} */
